// import ReactPlayer from 'react-player';

//import {Player} from 'react-simple-player';
import ReactAudioPlayer from 'react-audio-player';
import React, { useState } from 'react';

// <Player src="https://file-examples.com/wp-content/uploads/2017/11/file_example_MP3_700KB.mp3" height={40} />

function AudioPlayer({ audioData, setAudioData }) {
  const [isLoading, setIsLoading] = useState(true);

  const handlePlay = () => {
    setIsLoading(false);
  };

  const stopPlay = () => {
    setAudioData(null);
  }


  const s3Url = 'https://ai-radio-files.s3.us-east-2.amazonaws.com';

  return (
    <div id="player" onBlur={stopPlay}>
      {isLoading && (
        <div>Loading...</div> // Display a loading spinner or other indicator
      )}
      <ReactAudioPlayer src={`${s3Url}/${audioData.audio_file}`} autoPlay controls onCanPlayThrough={handlePlay} />
    </div>

  );
}

export default AudioPlayer;